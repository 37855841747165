@import '../../../Styles/settings';

.root {
  position: fixed;
  right: 0;
  bottom: 15px;
  z-index: map-get($zIndexes, 'shareBtn');
  padding: 15px;
  display: flex;
  transform: translateX(100%);
  transition: transform 0.3s $main-timing-function;
  svg {
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  &.isOpen {
    background: white;
    max-width: 250px;
    transform: translateX(0%);
    @include styleComponent('style_id_shareButton_iconButton') {
      background-color: transparent;
      background-image: url(/Dist/img/icons/cross-black.svg);
      transform: translateX(0);
    }
  }

  @include styleComponent('style_id_shareButton_iconButton') {
    background: url(/Dist/img/icons/share-icon-white.svg) no-repeat;
    background-size: 16px;
    background-position: center;
    background-color: $color-brand;
    letter-spacing: 1.2px;
    width: 40px;
    height: 40px;
    margin-left: 20px;
    transition: background-color 0.3s $main-timing-function,
      transform 0.3s $main-timing-function;
    transform: translateX(calc(-100% - 50px));
  }
}

.textBtnWrapper {
  width: 40px;
  margin-left: auto;
}

.item {
  height: 40px;
  width: 40px;

  &:not(:last-of-type) {
    margin: 0 10px 0 0;
  }
}

.shareContainer {
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.shareText {
  display: none;
}

@media #{$md-up} {
  .root {
    position: relative;
    right: initial;
    bottom: initial;
    padding: 0;
    transform: none;
    margin-left: 0;
    z-index: map-get($zIndexes, 'shareBtn');

    @include styleComponent('style_id_shareButton_iconButton') {
      background-color: transparent;
      background-image: url(/Dist/img/icons/share-icon-scarpa-flow.svg);
      background-position: left;
      padding-left: 20px;
      background-size: 10px;
      font-size: 12px;
      width: auto;
      padding-right: 0;
      padding-bottom: 0;
      transform: none;
      margin-left: 0;
      min-width: 70px;
      justify-content: center;
    }

    &.isOpen {
      width: 100%;
      max-width: none;
      @include styleComponent('style_id_shareButton_iconButton') {
        background-color: white;
        background-position: center;
        width: 70px;
        margin-left: auto;
        background-size: 14px;
      }
      &.autoWidthMd {
        width: auto;
      }
    }
  }

  .shareContainer {
    position: absolute;
    right: 0px;
    top: 8px;
    flex-direction: column;
    height: auto;
    background: white;
    padding: 36px 15px 15px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  }

  .shareText {
    text-transform: uppercase;
    display: inline;
    font-weight: 400;
    &.isOpen {
      display: none;
    }
  }

  .shareBtnAbsolutePos {
    position: absolute;
    right: 0px;
    top: 8px;
  }

  .item {
    width: 40px;
    height: 40px;
    margin: 10px 0 0 0;
    &:not(:last-of-type) {
      margin: 10px 0 0 0;
    }
  }
}

@include media($content-container-width) {
  .shareBtn {
    right: 0;
  }
  .container {
    right: 0;
  }
}
