@import '../../Styles/settings';

.root {
  padding-top: $mobile-header-height;
}

.intro {
  font-weight: 500;
}

.topContent {
  width: 100%;
  padding-top: 40px;
  &:empty {
    padding-top: 0;
  }
}

.bodyTextContainer {
  &:empty {
    display: none;
  }
}

.spacerPageContentSubNav {
  margin-top: 40px;
}

.spacerBlockBottom {
  padding-bottom: $verticleBlockPaddingXS;
}

.spacerBlockTop {
  padding-top: 24px;
}

.categoryTitle {
  @include HelveticaMedium;
  font-size: 10px;
  line-height: 12px;
  color: $dark-gray;
  width: 100%;
  padding-bottom: 16px;
  text-transform: uppercase;
  &.spacingTop {
    padding-top: 24px;
  }
}

.categoryContainer {
  margin-bottom: 24px;
  overflow-x: auto;
  /* width */
  &::-webkit-scrollbar {
    display: none;
  }
}

.categoryWrapper {
  display: flex;
  width: max-content;
}

@media #{$sm-up} {
  .topContent {
    max-width: 770px;
    margin: 0 auto;
  }
}

@media #{$md-up} {
  .breadcrumb {
    margin-top: 16px;
    &.isHeroHidden {
      margin-top: 32px;
    }
  }

  .root {
    padding-top: $standard-header-height-md-up;
  }

  .rootPaddingBottom {
    padding-bottom: $verticleBlockPaddingMD;
  }

  .topContent {
    padding-top: 60px;
    &:empty {
      padding-top: 0;
    }
  }

  .shareButtonWrapper {
    max-width: $content-container-width;
    margin: 0 auto;
    padding: 0 $gutterContentArea;
  }

  .spacerBlockBottom {
    padding-bottom: $verticleBlockPaddingMD;
  }

  .spacerBlockTop {
    padding-top: 56px;
  }

  .categoryTitle {
    &.spacingTop {
      padding-top: 56px;
    }
  }
}

@media #{$lg-up} {
  .categoryWrapper {
    justify-content: flex-start;
    flex-wrap: wrap;
    width: unset;
  }
}
