@import '../../../Styles/settings';

.btn {
  @include textBtn;
}

.black {
  color: $text-color;
}

.white {
  color: #fff;
}

.blue {
  color: $midnight-blue;
}

.primary {
  color: $color-primary-1;
}
.noUpperCase {
  text-transform: none;
}

.isActive {
  color: $color-primary-1;
}
